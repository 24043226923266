import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import './Schedule.css'
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import flyer8 from '../../assets/img/flyer8.jpg';
import flyer7 from '../../assets/img/flyer7.jpg';
import flyer6 from '../../assets/img/flyer6.jpg';
import flyer5 from '../../assets/img/flyer5.jpg';
import flyer4 from '../../assets/img/flyer4.jpg';
import flyer3 from '../../assets/img/flyer3.jpg';
import flyer2 from '../../assets/img/flyer2.jpg';
import flyer1 from '../../assets/img/flyer1.jpg';
import flyer9 from '../../assets/img/flyer9.jpg';
import flyer10 from '../../assets/img/flyer10.png';
import flyer11 from '../../assets/img/flyer11.png';
import flyer12 from '../../assets/img/flyer12.jpeg';
import flyer13 from '../../assets/img/flyer13.jpg';
import flyer14 from '../../assets/img/flyer14.jpg';
import flyer15 from '../../assets/img/flyer15.jpg';
import flyer16 from '../../assets/img/flyer16.jpg';
import flyer17 from '../../assets/img/flyer17.jpg';
import GridItem from "components/Grid/GridItem.js";
import CustomTabs from "components/CustomTabs/CustomTabsSchedule.js";

import styles from "assets/jss/material-kit-react/views/profilePage.js";

const useStyles = makeStyles(styles);
const dashboardRoutes = [];

export default function SchedulePage(props) {
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div>
      <Link style={{ color: 'white' }} to="/" >
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand="IEEE CI Lectures"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 400,
            color: "white"
          }}
          {...rest}
        />
      </Link>
      <Parallax small filter style={{ background: "rgba(4, 35, 92, 0.8)" }} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div >
            <GridContainer justify="center">
              <h1 className={classes.title}>Schedule</h1>
            </GridContainer>
            <GridContainer justify="center">
              <h2 className={classes.title}>2023</h2>
            </GridContainer>
            <GridContainer justify="center" style={{ marginLeft: "1px", marginRight: "1px", marginBottom: '60px' }} >
              <GridItem xs={12} sm={12} md={4}>
                <CustomTabs
                  tabs={[
                    {
                      tabContent: (
                        <img style={{ maxWidth: '100%' }} src={flyer17}></img>
                      )
                    },
                  ]}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={8}>
                <h2 className={classes.title}>Goreti Marreiros</h2>
                <h3>Improving Personalized Elderly Care</h3>
                <h4>29 March - 17:00h</h4>
                <h4>Zoom Online: https://videoconf-colibri.zoom.us/j/86109989605</h4>
                <h4>In-Person: A1 Auditorium, Informatics Department, University of Minho, Braga, Portugal</h4>
                <h4 style={{ textAlign: "justify" }}><b>About the Activity:</b>The world ageing problem is prompting new sustainable ways to support elderly people combining technological breakthroughs and motivational strategies to persuade  elderly people to be healthier and stay active. In this work, a coaching system is presented, especially designed to support elderly people and motivate them to pursue healthier ways of living using different several gamification techniques together with a virtual assistant approach to translate user progress and enhance user interaction with the developed system.</h4>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center" style={{ marginLeft: "1px", marginRight: "1px", marginBottom: '60px' }} >
              <GridItem xs={12} sm={12} md={4}>
                <CustomTabs
                  tabs={[
                    {
                      tabContent: (
                        <img style={{ maxWidth: '100%' }} src={flyer16}></img>
                      )
                    },
                  ]}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={8}>
                <h2 className={classes.title}>Helen Crompton</h2>
                <h3>AIEd: A new era in Education</h3>
                <h4>23 February - 17:00h</h4>
                <h4>Zoom Online: https://videoconf-colibri.zoom.us/j/86109989605</h4>
                <h4>In-Person: A1 Auditorium, Informatics Department, University of Minho, Braga, Portugal</h4>
                <h4 style={{ textAlign: "justify" }}><b>About the Activity:</b>Artificial Intelligence has been widely adopted in schools and universities across the world. This presentation will cover the considerations of using AI in Education (AIEd), such as the affordance of this technology in education as well as the ethical challenges. Findings of two systematic reviews on AI in K-12 and AI in higher education will be presented. This includes information on contextual information, such as geographical interest in AIEd, academic levels, and disciplines, who the AI is intended for, and most importantly, trends in how AI is being used. With such powerful tools, ethical considerations are discussed alongside the uses of AIEd</h4>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center" style={{ marginLeft: "1px", marginRight: "1px", marginBottom: '60px' }} >
              <GridItem xs={12} sm={12} md={4}>
                <CustomTabs
                  tabs={[
                    {
                      tabContent: (
                        <img style={{ maxWidth: '100%' }} src={flyer15}></img>
                      )
                    },
                  ]}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={8}>
                <h2 className={classes.title}>Jaime Andres Rincon Arango</h2>
                <h3>From Cognitive Assistants to Robotic Cognitive Assistants</h3>
                <h4>18 January - 17:00h</h4>
                <h4>Zoom Online: https://videoconf-colibri.zoom.us/j/86109989605</h4>
                <h4 style={{ textAlign: "justify" }}><b>About the Activity:</b>Cognitive AI has driven virtual assistant services throughout their existence. The presence and capabilities of virtual assistants are increasing year after year. In fact, most of us have seen and used some of them, such as Alexa, Siri and Google Assistant. The underlying AI technology of these devices is a combination of natural language processing (NLP) and machine learning, as well as the integration of different hardware devices. These assistants make it possible to monitor, assist and improve the quality of life of users.The real power of these cognitive assistants lies in their ability to retrieve data and to do so in a conversational manner perfectly adapted to how we humans like to communicate, i.e. through voice, gestures and body language.</h4>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <h2 className={classes.title}>2022</h2>
            </GridContainer>
            
            <GridContainer justify="center" style={{ marginLeft: "1px", marginRight: "1px", marginBottom: '60px' }} >
              <GridItem xs={12} sm={12} md={4}>
                <CustomTabs
                  tabs={[
                    {
                      tabContent: (
                        <img style={{ maxWidth: '100%' }} src={flyer14}></img>
                      )
                    },
                  ]}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={8}>
                <h2 className={classes.title}>Javier Bajo Perez</h2>
                <h3>UPM research center in Artificial Intelligence. Experiences and Challenges.</h3>
                <h4>14 December - 17:00h</h4>
                <h4>Zoom Online: https://videoconf-colibri.zoom.us/j/86109989605</h4>
                <h4 style={{ textAlign: "justify" }}><b>About the Activity:</b>Artificial Intelligence is becoming increasingly relevant in our societies. In recent year, there have been major advances in artificial intelligence research. In this talk, Javier Perez will introduce the UPM research center in Artificial Intelligence, a research center with a broad experience in research in AI. The main research lines of te center will be presented, focusinf on specific projects. The research challenges that the center is addressign will also be discussed.</h4>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center" style={{ marginLeft: "1px", marginRight: "1px", marginBottom: '60px' }} >
              <GridItem xs={12} sm={12} md={4}>
                <CustomTabs
                  tabs={[
                    {
                      tabContent: (
                        <img style={{ maxWidth: '100%' }} src={flyer13}></img>
                      )
                    },
                  ]}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={8}>
                <h2 className={classes.title}>Tânia Di Mascio</h2>
                <h3>How to make an AI algorithm more "ecological"</h3>
                <h4>30 November - 17:00h</h4>
                <h4>Zoom Online: https://videoconf-colibri.zoom.us/j/86109989605</h4>
                <h4 style={{ textAlign: "justify" }}><b>About the Activity:</b> Nowadays, Artificial Intelligence, Sensors Technology, and Internet-of-Things affect people’s behavior in everyday life. Actually, technology in general, cannot yet be considered diversity-neutral: it is enough to consider, for example, a gender bias of smartphones that are primarily designed by males for the male hand size. The uniqueness, if valued, may constitute opportunities for the whole society. Hence the need to consider it in the technology life cycle.
                  This talk presents an Internet-of-Things solution represented by smart totems for advertisement and way-finding services within advanced ICT-based shopping malls, that are conceived as a sentient spaces. To take into account the specificity of final users and to increase awareness of the people's uniqueness in the design of Internet-of-Things, a User-Centered Design methodology has been used. Scenarios and Personas have been created to consciously seek diversity biases. This identification is then exploited to make Artificial Intelligence algorithms ecological, i.e., adapted to the real context of use.</h4>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center" style={{ marginLeft: "1px", marginRight: "1px", marginBottom: '60px' }} >
              <GridItem xs={12} sm={12} md={4}>
                <CustomTabs
                  tabs={[
                    {
                      tabContent: (
                        <img style={{ maxWidth: '100%' }} src={flyer12}></img>
                      )
                    },
                  ]}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={8}>
                <h2 className={classes.title}>João C. Ferreira</h2>
                <h3>Artificial Intelligence in the Public Services</h3>
                <h4>19 October - 17:00h</h4>
                <h4>Zoom Online: https://videoconf-colibri.zoom.us/j/86109989605</h4>
                <h4 style={{ textAlign: "justify" }}><b>About the Activity:</b> Case studies of university and civil service collaboration in problem solving using Artificial Intelligence.</h4>
              </GridItem>
            </GridContainer>



            <GridContainer justify="center" style={{ marginLeft: "1px", marginRight: "1px", marginBottom: '60px' }} >
              <GridItem xs={12} sm={12} md={4}>
                <CustomTabs
                  tabs={[
                    {
                      tabContent: (
                        <img style={{ maxWidth: '100%' }} src={flyer11}></img>
                      )
                    },
                  ]}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={8}>
                <h2 className={classes.title}>Flavio Santos</h2>
                <h3>Interpretability and right for the right reasons in Deep Learning</h3>
                <h4>20 July - 17:00h</h4>
                <h4>Zoom Online: https://videoconf-colibri.zoom.us/j/86109989605</h4>
                <h4 style={{ textAlign: "justify" }}><b>About the Activity:</b> Deep learning architectures are among the state-of-the-art models in various domains, such as natural language processing, computer vision, and recommender systems. However, although they have such accuracy, the literature shows that they have limitations: (1) vulnerability to adversarial attacks (i.e., they return wrong predictions to new input with a little noise), (2) poor performance in data out-of-distribution, (3) Right for the wrong reasons, and the (4) difficulty to be interpretable. The existence of these limitations cause a warning of deep learning applications in high stakes domains such as self-driving, financial investment, clinical diagnosis, law decisions, and others. In high stakes applications, the model accuracy is crucial but not enough, it is necessary that the model makes a decision based on the right signal and we can obtain its decision interpretability. Therefore, the deep learning models are now demanded for properties such as robustness, reliability, and interpretability. Given the importance of these topics, in this talk we will present a review about the state-of-the-art for interpretability and right for the right reasons methods in deep learning.</h4>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center" style={{ marginLeft: "1px", marginRight: "1px", marginBottom: '60px' }} >
              <GridItem xs={12} sm={12} md={4}>
                <CustomTabs
                  tabs={[
                    {
                      tabContent: (
                        <img style={{ maxWidth: '100%' }} src={flyer10}></img>
                      )
                    },
                  ]}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={8}>
                <h2 className={classes.title}>Esteban Jove</h2>
                <h3>Implementation of virtual sensors and one-class classifiers to detect anomalous events</h3>
                <h4>15 June - 17:00h</h4>
                <h4>Zoom Online: https://videoconf-colibri.zoom.us/j/86109989605</h4>
                <h4 style={{ textAlign: "justify" }}><b>About the Activity:</b> This conference deals with the analysis and implementation of anomaly detection systems based on intelligent techniques. Specifically, two of the most commonly used strategies for this purpose are presented, studied and analysed. The first consists on the virtual sensor development based on a hybrid intelligent model capable of detecting anomalous situations. The second strategy is based on the use of one-class techniques, used to implement anomalies detection classifiers. Therefore, an analysis and a comparison of both strategies are accomplished through different real applications, highlighting the performance of each one.</h4>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <h2 className={classes.title}>2021</h2>
            </GridContainer>
            <GridContainer justify="center" style={{ marginLeft: "1px", marginRight: "1px", marginBottom: '60px' }} >
              <GridItem xs={12} sm={12} md={4}>
                <CustomTabs
                  tabs={[
                    {
                      tabContent: (
                        <img style={{ maxWidth: '100%' }} src={flyer9}></img>
                      )
                    },
                  ]}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={8}>
                <h2 className={classes.title}>Héctor Alaiz Moretón</h2>
                <h3>Artificial Intelligence and Cybersecurity.</h3>
                <h4>16 June - 17:00h</h4>
                <h4>Zoom Online: https://videoconf-colibri.zoom.us/j/86109989605</h4>
                <h4 style={{ textAlign: "justify" }}><b>About the Activity:</b>Nowadays, the large number of devices working like sensors and actuators that make up the Internet of
things forces these systems to use diverse technologies and protocols.
IoT networks are more heterogeneous and complex than regular networks. Due to this fact, new
challenges in cybersecurity to protect these systems and devices with special features emerge.
Artificial intelligence techniques are real solution for protecting IoT systems from the various anomalies
and attacks at the network level.
An approach can be the Intrusion Detection Systems (IDS) improved through machine and deep learning
techniques if we have the correct information based on high quality datasets.</h4>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center" style={{ marginLeft: "1px", marginRight: "1px", marginBottom: '60px' }} >
              <GridItem xs={12} sm={12} md={4}>
                <CustomTabs
                  tabs={[
                    {
                      tabContent: (
                        <img style={{ maxWidth: '100%' }} src={flyer8}></img>
                      )
                    },
                  ]}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={8}>
                <h2 className={classes.title}>António Abelha</h2>
                <h3>OpenEHR - The solution for an interoperable development</h3>
                <h4>2 June - 17:00h</h4>
                <h4>Zoom Online: https://videoconf-colibri.zoom.us/j/86109989605</h4>
                <h4 style={{ textAlign: "justify" }}><b>About the Activity:</b>OpenEHR' is the name of a technology for e-health, consisting of open specifications, clinical models
and software that can be used to create standards, and build information and interoperability solutions
for healthcare. </h4>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center" style={{ marginLeft: "1px", marginRight: "1px", marginBottom: '60px' }} >
              <GridItem xs={12} sm={12} md={4}>
                <CustomTabs
                  tabs={[
                    {
                      tabContent: (
                        <img style={{ maxWidth: '100%' }} src={flyer7}></img>
                      )
                    },
                  ]}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={8}>
                <h2 className={classes.title}>Luis Fernando Castillo</h2>
                <h3>The AI we have and the AI we need</h3>
                <h4>19 May - 17:00h</h4>
                <h4>Zoom Online: https://videoconf-colibri.zoom.us/j/86109989605</h4>
                <h4 style={{ textAlign: "justify" }}><b>About the Activity:</b> The interests that have driven recent breakthroughs in AI have focused on supercomputing
technologies that specialise in exploiting huge databases. Years from now, historians of technology
may wonder how the AI research community of our time could have allowed almost all of its
resources to be mobilised in these data-tuning technologies and, by contrast, so little in basic
science to address new approaches.
However, there are important issues that these pattern exploitation technologies raise for us. They
forgo asking "why do things happen?" and restrict themselves to knowing "what things happen?".
There is a fundamental shift in the notion of understanding a phenomenon: these strategies cannot
be assessed in terms of whether they are right or wrong but in terms of whether they succeed or fail
in their predictions. In this talk we will illustrate what problems we need to solve and where solutions
may come from.</h4>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center" style={{ marginLeft: "1px", marginRight: "1px", marginBottom: '60px' }} >
              <GridItem xs={12} sm={12} md={4}>
                <CustomTabs
                  tabs={[
                    {
                      tabContent: (
                        <img style={{ maxWidth: '100%' }} src={flyer6}></img>
                      )
                    },
                  ]}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={8}>
                <h2 className={classes.title}>Ana Madureira</h2>
                <h3>AI for Intelligent Scheduling Systems</h3>
                <h4>12 May - 17:00h</h4>
                <h4>Zoom Online: https://videoconf-colibri.zoom.us/j/86109989605</h4>
                <h4 style={{ textAlign: "justify" }}><b>About the Activity:</b> Scheduling is a critical function that is present throughout many industries and applications. A great need exists for
developing scheduling approaches that can be applied to a number of different scheduling problems with significant
impact on performance of business organizations. A challenge is emerging in the design of scheduling support
systems for manufacturing environments where dynamic adaptation and optimization become increasingly
important. In this lecture, Self-Optimizing Mechanism for Scheduling System through Nature Inspired Optimization
Techniques will be described.</h4>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center" style={{ marginLeft: "1px", marginRight: "1px", marginBottom: '60px' }} >
              <GridItem xs={12} sm={12} md={4}>
                <CustomTabs
                  tabs={[
                    {
                      tabContent: (
                        <img style={{ maxWidth: '100%' }} src={flyer5}></img>
                      )
                    },
                  ]}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={8}>
                <h2 className={classes.title}>João Vilaça</h2>
                <h3>Smart Human - Machine Interface for Robotic Surgery</h3>
                <h4>21 April - 17:00h</h4>
                <h4>Zoom Online: https://videoconf-colibri.zoom.us/j/86109989605</h4>
                <h4 style={{ textAlign: "justify" }}><b>About the Activity:</b> The Applied Artificial Intelligence Laboratory (2Ai) pursuies applied research advances on artificial intelligence
(AI), namely in intelligent systems, human-AI collaboration and robotics for health, industry, environment and
security. This cross-cutting themes and multidisciplinary interface fosters the generation of value through the
development of innovative products and smart services, resulting from internationally highly competitive
research. This presentation will focus on two medical applications currently under execution in the 2Ai
laboratory, namely: (i) a new collaborative robotic application for navigation/guidance of percutaneous renal
accesses; and (ii) a new human-AI collaboration system using a collaborative robot for laser-based treatments
of vascular lesions.
1) In the last years, the research team has developed a novel hand-held real-time electromagnetic navigation
system for percutaneous renal access. The current system was extensively validated, in a first phase, on
experimental animal models and, more recently, on human trials. Overall, it proved to be highly accurate,
simple and quicker than the traditional clinical approach. Nevertheless, recent experiments have suggested
that the system is still completely non-independent of surgeon expertise, namely, to correctly maneuver and
handle the needle in a 3D space during puncture. In this sense, the current team developed a new human-AI
setup, where a collaborative robot was embedded with the previous navigation system to support the entire
procedure. The collaborative robot monitors the physician operation, limiting the degrees of freedom
throughout the needle manipulation, preventing incorrect puncture sites and facilitating the entire procedure.
2) The research team is also pursuing the development of new natural, smart and collaborative strategies to
improve laser-based treatments of vascular lesions. Nowadays, the success of the laser-based treatment is
totally dependent on the physician's ability to handle the laser, which is clearly a sub-optimal approach. The
current team developed a novel medical treatment where a collaborative robot is combined with a set of
sensors and an AI system, automatically detecting the vascular lesions and guiding the operator to the
optimal treatment spot. Overall, with the current system, we expect to reduce the treatment time, improve the
accuracy of the laser treatment and ultimately improve the current medical approach with a limited number of
complications.</h4>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center" style={{ marginLeft: "1px", marginRight: "1px", marginBottom: '60px' }} >
              <GridItem xs={12} sm={12} md={4}>
                <CustomTabs
                  tabs={[
                    {
                      tabContent: (
                        <img style={{ maxWidth: '100%' }} src={flyer4}></img>
                      )
                    },
                  ]}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={8}>
                <h2 className={classes.title}>Dalila Durães</h2>
                <h3>Artificial Intelligence in Education.</h3>
                <h4>07 April - 17:00h</h4>
                <h4>Zoom Online: https://videoconf-colibri.zoom.us/j/86109989605</h4>
                <h4 style={{ textAlign: "justify" }}><b>About the Activity:</b> We live in a hyper-connected world, where technology is present in all spheres of our life.
Furthermore, it is the backbone for transforming our society, which is constantly changing and requires a
continuous adaptation of human beings to the environment. When artificial intelligence is applied on the
field of education student, teacher, and educational institutions benefit from it. There are several
technologies that are applied in education, but we are in the beginning of the process. So, advances in
artificial intelligence open to new possibilities and challenges for teaching and learning in education, with
the potential to fundamentally change governance and the internal architecture of institutions of
education.</h4>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center" style={{ marginLeft: "1px", marginRight: "1px", marginBottom: '60px' }} >
              <GridItem xs={12} sm={12} md={4}>
                <CustomTabs
                  tabs={[
                    {
                      tabContent: (
                        <img style={{ maxWidth: '100%' }} src={flyer3}></img>
                      )
                    },
                  ]}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={8}>
                <h2 className={classes.title}>José Luis Calvo Rolle</h2>
                <h3>Optimization modeling and fault detection in real systems using intelligent techniques.</h3>
                <h4>24 March - 17:00h</h4>
                <h4>Zoom Online: https://videoconf-colibri.zoom.us/j/86109989605</h4>
                <h4 style={{ textAlign: "justify" }}><b>About the Activity:</b> There are many challenges we face today, and many of them related to cross-cutting interests such as sustainability, efficiency, zero impact, economic
          performance, and so on. In order to achieve the most of these aspects, and trying
          to maintain or improve what already exists, it is necessary to attend to optimization
          tasks of the infrastructures in operation. To achieve this desired optimization, it is
          essential to know the different systems behavior in order to act on them. Also,
          early detection of anomalies and failures contributes successfully to the efficiency,
          performance and availability of infrastructures. In this conference thanks to the
          use of intelligent techniques and over real examples it is shown how to achieve
          the above objectives for accomplishing the optimization of the systems.</h4>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center" style={{ marginLeft: "1px", marginRight: "1px", marginBottom: '60px' }} >
              <GridItem xs={12} sm={12} md={4}>
                <CustomTabs
                  tabs={[
                    {
                      tabContent: (
                        <img style={{ maxWidth: '100%' }} src={flyer2}></img>
                      )
                    },
                  ]}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={8}>
                <h2 className={classes.title}>Luis M. P. Correia</h2>
                <h3>Artificial Intelligence Going Places</h3>
                <h4>10 March - 17:00h</h4>
                <h4>Zoom Online: https://videoconf-colibri.zoom.us/j/86109989605</h4>
                <h4 style={{ textAlign: "justify" }}><b>About the Activity:</b> Artificial intelligence (AI) has been pervading different domains of activity with a recent burst resulting from scientific and technological advancements in the field. Autonomous driving, cognitive computation, board game playing are some of the most publicised ones. A brief review of different success cases will be followed by a description of advancements that are currently under research with relevant first steps. The particular aspect of new forms of embodiment of intelligence will be analysed in more detail. Computational systems are typically static and have a limited sensorial capability. However, the networking capability of such systems allows them access to enormous amounts of data and to a growing number of devices sensing the physical world, which increase their knowledge and perception capabilities. This connection goes beyond physical devices. Computational intelligence teaming with humans and other living forms is stepping up. The possibilities open are vast and the society must take an active part on guiding their development.</h4>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center" style={{ marginLeft: "1px", marginRight: "1px", marginBottom: '60px' }} >
              <GridItem xs={12} sm={12} md={4}>
                <CustomTabs
                  tabs={[
                    {
                      tabContent: (
                        <img style={{ maxWidth: '100%' }} src={flyer1}></img>
                      )
                    },
                  ]}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={8}>
                <h2 className={classes.title}>Pedro Melo Pinto</h2>
                <h3>Wine grape ripeness assessment using Hyperspectral Imaging and Machine Learning</h3>
                <h4>24 February - 17:00h</h4>
                <h4>Zoom Online: https://videoconf-colibri.zoom.us/j/86109989605</h4>
                <h4 style={{ textAlign: "justify" }}><b>About the Activity:</b>  The wine industry has been striving to achieve differentiation in their products and to improve their quality and consistency, which involves harvesting grapes at the optimal maturity point and selecting them according to the desired characteristics of the wine to be produced. In this context, hyperspectral imaging (HSI) combined with machine learning algorithms (ML) is a promising alternative to predict important oenological parameters and assist on harvesting critical decisions. However, the large amount of data generated by HSI, together with the large variability associated with the problem (varieties involved, climate, terroir, etc.), raise unusual challenges for data-driven modelling. Several ML approaches have been proposed to handle such data characteristics, but selecting a suitable methodology that best address the problem under study and make sure it generalizes well, is a cumbersome task. Our work is focused in two fundamental and novel aspects to address the natural variability arising from different grape varieties, vintages and growth conditions: the essential wavelength bands selection (with the purpose of reducing the dimensionality of data without losing predictive power) and the generalization ability of the ML model under such demanding conditions.</h4>
              </GridItem>
            </GridContainer>
            
            <br /><br />
          </div>
        </div>

      </div>
      <Footer />
    </div>
  );
}
