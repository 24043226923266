import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
// import flyer8  from '../../assets/img/flyer8.jpg';
import flyer7 from '../../assets/img/flyer7.jpg';
import flyer6 from '../../assets/img/flyer6.jpg';
import flyer5 from '../../assets/img/flyer5.jpg';
import flyer4 from '../../assets/img/flyer4.jpg';
import flyer3 from '../../assets/img/flyer3.jpg';
import flyer2 from '../../assets/img/flyer2.jpg';
import flyer1 from '../../assets/img/flyer1.jpg';
import flyer9 from '../../assets/img/flyer9.jpg';
import flyer10 from '../../assets/img/flyer10.png';
import flyer11 from '../../assets/img/flyer11.png';
import flyer12 from '../../assets/img/flyer12.jpeg';
import flyer13 from '../../assets/img/flyer13.jpg';
import flyer14 from '../../assets/img/flyer14.jpg';
import flyer15 from '../../assets/img/flyer15.jpg';
import flyer16 from '../../assets/img/flyer16.jpg';
import flyer17 from '../../assets/img/flyer17.jpg';
import First from "./Sections/First"
import "./Home.css"
const dashboardRoutes = [];

const useStyles = makeStyles(styles);

const flyer8 = require('../../assets/img/flyer8.jpg');

export default function HomePage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Link style={{ color: 'white' }} to="/" >
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand="CI Lectures"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 400,
            color: "white"
          }}
          {...rest}
        />
      </Link>
      <Parallax filter style={{background:" rgba(4, 35, 92, 0.8)"}}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title} >IEEE CI Lectures</h1>
              <h4 >
                IEEE Computational Intelligence Society Chapter, Portugal
              </h4>
              <br />
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <First />
        </div>
      </div>
      <div style={{ marginTop: "60px" }} className={classNames(classes.main, classes.mainRaised)} >
        <div className={classes.section}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={8} style={{ marginTop: "60px" }}>
              <h1 style={{ color: "#3C4858", fontFamily: "Roboto Slab, Times New Roman, serif", fontWeight: "700", fontSize: "2.25rem", textAlign: "center", marginTop: "30px", marginBottom: "60px" }}>Schedule Lectures</h1>
            </GridItem>
          </GridContainer>
        </div>
        <GridContainer justify="center" style={{ marginLeft: "1px", marginRight: "1px", marginBottom: '60px' }} >
          <GridItem xs={12} sm={12} md={12}><center><h2>2023</h2></center></GridItem>
          
          <GridItem xs={12} sm={12} md={4}>
            <CustomTabs
              headerColor="info"
              tabs={[
                {
                  tabName: "29 March - Goreti Marreiros",
                  tabContent: (
                    <img style={{maxWidth:'100%'}} src={flyer17}></img>
                  )
                },
              ]}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <CustomTabs
              headerColor="info"
              tabs={[
                {
                  tabName: "23 February - Helen Crompton",
                  tabContent: (
                    <img style={{maxWidth:'100%'}} src={flyer16}></img>
                  )
                },
              ]}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <CustomTabs
              headerColor="info"
              tabs={[
                {
                  tabName: "18 January - Jaime A. R. Arango",
                  tabContent: (
                    <img style={{maxWidth:'100%'}} src={flyer15}></img>
                  )
                },
              ]}
            />
          </GridItem>
          </GridContainer>
        <GridContainer justify="center" style={{ marginLeft: "1px", marginRight: "1px", marginBottom: '60px' }} >
          <GridItem xs={12} sm={12} md={12}><center><h2>2022</h2></center></GridItem>
          
        <GridItem xs={12} sm={12} md={4}>
            <CustomTabs
              headerColor="info"
              tabs={[
                {
                  tabName: "14 December - Javier Bajo Perez",
                  tabContent: (
                    <img style={{maxWidth:'100%'}} src={flyer14}></img>
                  )
                },
              ]}
            />
          </GridItem>
        <GridItem xs={12} sm={12} md={4}>
            <CustomTabs
              headerColor="info"
              tabs={[
                {
                  tabName: "30 November - Tânia Di Mascio",
                  tabContent: (
                    <img style={{maxWidth:'100%'}} src={flyer13}></img>
                  )
                },
              ]}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <CustomTabs
              headerColor="info"
              tabs={[
                {
                  tabName: "19 October - João C. Ferreira",
                  tabContent: (
                    <img style={{maxWidth:'100%'}} src={flyer12}></img>
                  )
                },
              ]}
            />
          </GridItem><GridItem xs={12} sm={12} md={4}>
            <CustomTabs
              headerColor="info"
              tabs={[
                {
                  tabName: "20 july - Flávio Santos",
                  tabContent: (
                    <img style={{maxWidth:'100%'}} src={flyer11}></img>
                  )
                },
              ]}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <CustomTabs
              headerColor="info"
              tabs={[
                {
                  tabName: "15 june - Esteban Jove",
                  tabContent: (
                    <img style={{maxWidth:'100%'}} src={flyer10}></img>
                  )
                },
              ]}
            />
          </GridItem>
      </GridContainer>
      <GridContainer>
      <GridItem xs={12} sm={12} md={12}><center><h2>2021</h2></center></GridItem>

        <GridItem xs={12} sm={12} md={4}>
            <CustomTabs
              headerColor="info"
              tabs={[
                {
                  tabName: "16 june - Héctor Alaiz Moretón",
                  tabContent: (
                    <img style={{maxWidth:'100%'}} src={flyer9}></img>
                  )
                },
              ]}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <CustomTabs
              headerColor="info"
              tabs={[
                {
                  tabName: "2 june - António Abelha",
                  tabContent: (
                    <img style={{maxWidth:'100%'}} src={flyer8}></img>
                  )
                },
              ]}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <CustomTabs
              headerColor="info"
              tabs={[
                {
                  tabName: "19 May - Luis Fernando Castillo",
                  tabContent: (
                    <img style={{maxWidth:'100%'}} src={flyer7}></img>
                  )
                },
              ]}
            />
          </GridItem>
        <GridItem xs={12} sm={12} md={4}>
            <CustomTabs
              headerColor="info"
              tabs={[
                {
                  tabName: "12 May - Ana Madureira",
                  tabContent: (
                    <img style={{maxWidth:'100%'}} src={flyer6}></img>
                  )
                },
              ]}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <CustomTabs
              headerColor="info"
              tabs={[
                {
                  tabName: "21 April - João Vilaça",
                  tabContent: (
                    <img style={{maxWidth:'100%'}} src={flyer5}></img>
                  )
                },
              ]}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <CustomTabs
              headerColor="info"
              tabs={[
                {
                  tabName: "7 April - Dalila Durães",
                  tabContent: (
                    <img style={{maxWidth:'100%'}} src={flyer4}></img>
                  )
                },
              ]}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <CustomTabs
              headerColor="info"
              tabs={[
                {
                  tabName: "24 March - José Luis Rolle",
                  tabContent: (
                    <img style={{maxWidth:'100%'}} src={flyer3}></img>
              
                  )
                },
              ]}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <CustomTabs
              headerColor="info"
              tabs={[
                {
                  tabName: "10 March - Luis M. P. Correia",
                  tabContent: (
                    <img style={{maxWidth:'100%'}} src={flyer2}></img>
              
                  )
                },
              ]}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <CustomTabs
              headerColor="info"
              tabs={[
                {
                  tabName: "24 February - Pedro Melo Pinto",
                  tabContent: (
                    <img style={{maxWidth:'100%'}} src={flyer1}></img>
                  )
                },
              ]}
            />
          </GridItem>
          
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} >

          </GridItem>
        </GridContainer>
        
      </div>
      <Footer />
    </div>
  );
}
