import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import GridItem from "components/Grid/GridItem";
import jmachado from '../../assets/img/machado.jpg'
import novais from '../../assets/img/novais.jpg'
import jferreira from '../../assets/img/jferreira.png'
import dalila from '../../assets/img/daliladuraes.jpg'
import rsousa from '../../assets/img/rsousa.jpg'
import CustomTabs from "components/CustomTabs/CustomTabsSchedule.js";
import Button from '@material-ui/core/Button';
import WebIcon from '@material-ui/icons/Language';
import RGIcon from '@material-ui/icons/LibraryBooks';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import MailIcon from '@material-ui/icons/MailOutline';
const useStyles = makeStyles(styles);
const dashboardRoutes = [];

export default function CommitteePage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Link style={{ color: 'white' }} to="/" >
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand="IEEE CI Lectures"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 400,
            color: "white"
          }}
          {...rest}
        />
      </Link>
      <Parallax small filter style={{ background: " rgba(4, 35, 92, 0.8)" }} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          
          <div className={classes.container}>
            <GridContainer justify="center">
              <h1 className={classes.title}>Committee Members</h1>
            </GridContainer>
            <h2><b>General Chair</b></h2>
            <GridContainer justify="center" style={{ marginLeft: "1px", marginRight: "1px", marginBottom: '60px' }} >
              <GridItem xs={12} sm={12} md={4}>
                <CustomTabs
                  tabs={[
                    {
                      tabContent: (
                        <div>
                          <center><h3><b>Dalila Durães</b></h3></center>
                          <img style={{ width: '270px' }} src={dalila}></img>
                          <GridContainer spacing={2} style={{ marginLeft: '2vw' }}>
                            <GridItem xs={12} sm={12} md={2}><Button onClick={() => window.open("", "_blank")}><WebIcon /></Button></GridItem>
                            <GridItem xs={12} sm={12} md={2}><Button onClick={() => window.open("https://www.researchgate.net/profile/Dalila_Duraes", "_blank")}><RGIcon /></Button></GridItem>
                            <GridItem xs={12} sm={12} md={2}><Button onClick={() => window.open("https://www.linkedin.com/in/dalila-duraes-237ba95b/", "_blank")}><LinkedInIcon /></Button></GridItem>
                            <GridItem xs={12} sm={12} md={2}><Button onClick={() => window.open("mailto:dalila.duraes@algoritmi.uminho.pt", "_blank")}><MailIcon /></Button></GridItem>
                          </GridContainer>
                        </div>
                      )
                    },
                  ]}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={8} style={{ textAlign:'justify' }}>
              Dalila Durães is a pos-doctoral researcher at the ALGORITMI Center at the School of Engineering of the University of Minho, Braga, Portugal, where she collaborates with the group ISLab - Synthetic Intelligence lab.
She received her degree in Electronics and Computer Science Engineer in 1995, and in 2004 she finished her MSc in Industrial Electronics Engineering in Automation and Robotics at University of Minho, Portugal.
She has two International PhD: one in Education Science in Professor, Curricula and Educations Institutions at University of Granada, Spain; and other in Artificial Intelligence at Technical University of Madrid, Spain.
She develops scientific research in the field of Artificial Intelligence, with applications in Intelligent Tutoring Systems, Human-Computer Interaction, Behavioral Analysis, Natural Language Processing, Sentiment Analysis, Human Action Recognition, and the incorporation of AI methods and techniques in these fields.
Dalila has authored of over 31 publications in peer-reviewed international journals, book chapters and conference proceedings. She is also member of the editorial board of several international journals. During the last years she has served as an expert/reviewer of several institutions such as Conference Papers, journal. She was also co-oriented of MSc and PhD students, and she was member of jury of doctoral thesis and MSc thesis.
She is a member of the Portuguese Association for Artificial Intelligence (APPIA) and, since January 2020, a member of the association Direction Board. In addition, she is also a member of the Portuguese Robotics Society. She started her career developing scientific research in the field of collaborative learning, e-learning environment, learning activities, and Ambient Intelligent Systems absorbed her interest in the last years.
</GridItem>
            </GridContainer>
            
            
            <br />
            <h2><b>Program Chairs</b></h2>
            <GridContainer justify="center" style={{ marginLeft: "1px", marginRight: "1px", marginBottom: '60px' }} >
              <GridItem xs={12} sm={12} md={4}>
                <CustomTabs
                  tabs={[
                    {
                      tabContent: (
                        <div>
                          <center><h3><b>Paulo Novais</b></h3></center>
                          <img style={{ width: '270px' }} src={novais}></img>
                          <GridContainer spacing={2} style={{ marginLeft: '2vw' }}>
                            <GridItem xs={12} sm={12} md={2}><Button onClick={() => window.open("http://www4.di.uminho.pt/~pjn/", "_blank")}><WebIcon /></Button></GridItem>
                            <GridItem xs={12} sm={12} md={2}><Button onClick={() => window.open("https://www.researchgate.net/profile/Paulo_Novais", "_blank")}><RGIcon /></Button></GridItem>
                            <GridItem xs={12} sm={12} md={2}><Button onClick={() => window.open("https://www.linkedin.com/in/paulo-novais-39b5282/", "_blank")}><LinkedInIcon /></Button></GridItem>
                            <GridItem xs={12} sm={12} md={2}><Button onClick={() => window.open("mailto: pjon@di.uminho.pt", "_blank")}><MailIcon /></Button></GridItem>
                          </GridContainer>
                        </div>
                      )
                    },
                  ]}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={8} style={{ marginTop: '15vh',textAlign:'justify'  }}>
              Paulo Novais is a Full Professor of Computer Science/Artificial Intelligence at the University of Minho and a researcher at the ALGORITMI Centre, in which he is the coordinator the R&D line in Computer Science and Technology.
His main research aim is to make systems a little smarter, intelligent and reliable.
Former president of the Portuguese Association for AI, Senior member of the IEEE and Coordinator of the Scientific Committee - Program “New Talent in AI” at Calouste Gulbenkian Foundation.
</GridItem>
            </GridContainer>
            <GridContainer justify="center" style={{ marginLeft: "1px", marginRight: "1px", marginBottom: '60px' }} >
              <GridItem xs={12} sm={12} md={4}>
                <CustomTabs
                  tabs={[
                    {
                      tabContent: (
                        <div>
                          <center><h3><b>João Ferreira</b></h3></center>
                          <img style={{ width: '270px' }} src={jferreira}></img>
                          <GridContainer spacing={2} style={{ marginLeft: '2vw' }}>
                            <GridItem xs={12} sm={12} md={2}><Button onClick={() => window.open("https://ciencia.iscte-iul.pt/authors/joao-carlos-amaro-ferreira/cv", "_blank")}><WebIcon /></Button></GridItem>
                            <GridItem xs={12} sm={12} md={2}><Button onClick={() => window.open("https://www.researchgate.net/profile/Joao-Ferreira", "_blank")}><RGIcon /></Button></GridItem>
                            <GridItem xs={12} sm={12} md={2}><Button onClick={() => window.open("https://www.linkedin.com/in/joao-carlos-amaro-ferreira-bbbb102a/", "_blank")}><LinkedInIcon /></Button></GridItem>
                            <GridItem xs={12} sm={12} md={2}><Button onClick={() => window.open("mailto:joao.carlos.ferreira@iscte-iul.pt", "_blank")}><MailIcon /></Button></GridItem>
                          </GridContainer>
                        </div>
                      )
                    },
                  ]}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={8} style={{ marginTop: '7vh',textAlign:'justify'  }}>
              João Ferreira is an Assistant Professor with aggregation at ISCTE-IUL. He graduated in Physics from the Technical University of Lisbon (UTL / IST), Portugal, received an MSc in Telecommunications and a PhD in Computer Engineering from UTL / IST and a second PhD in Industrial Engineering at the University of Minho. His research interests are in: data science, Text Mining, IoT, AI, ITS, network security, Blockchain, Electric Vehicle, Intelligent Transport Systems (ITS) and sustainable mobility systems.
He has authored more than 200 papers in computer science. He has executed more than 30 projects (6 as PI), more than 150 scientific paper reviews and more than 20 scientific project evaluations.
President of IEEE CIS 2016-2018 and main organizer of international conferences such as: OAIR 2013, INTSYS 2018, INTSYS 2019 and INTSYS2020. IEEE senior member since 2015.
Guest Editor and topic editor of MDPI in the topics of energies, electronics and Sensors. President of the IEEE CIS in PT (2017-2018). Author of a patent in the area of Edge Computer in a monitoring system for fishing vessels.
Coordinator of the Master of Decision Support Systems and of the summer schools (smart cities) and winter schools (IoT Systems and blockchain) 2019 and 2020.
</GridItem>
            </GridContainer>
       <br></br>
            <h2><b>Organization Chairs</b></h2>
            <GridContainer justify="center" style={{ marginLeft: "1px", marginRight: "1px", marginBottom: '60px' }} >
              <GridItem xs={12} sm={12} md={4}>
                <CustomTabs
                  tabs={[
                    {
                      tabContent: (
                        <div>
                          <center><h3><b>José Machado</b></h3></center>
                          <img style={{ width: '270px' }} src={jmachado}></img>
                          <GridContainer spacing={2} style={{ marginLeft: '2vw' }}>
                            <GridItem xs={12} sm={12} md={2}><Button onClick={() => window.open("http://gia1.di.uminho.pt/~josemachado/", "_blank")}><WebIcon /></Button></GridItem>
                            <GridItem xs={12} sm={12} md={2}><Button onClick={() => window.open("https://www.researchgate.net/profile/Jose_Machado7", "_blank")}><RGIcon /></Button></GridItem>
                            <GridItem xs={12} sm={12} md={2}><Button onClick={() => window.open("https://www.linkedin.com/in/jmmachado/", "_blank")}><LinkedInIcon /></Button></GridItem>
                            <GridItem xs={12} sm={12} md={2}><Button onClick={() => window.open("mailto:jmac@di.uminho.pt", "_blank")}><MailIcon /></Button></GridItem>
                          </GridContainer>
                        </div>
                      )
                    },
                  ]}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={8} style={{ marginTop: '15vh',textAlign:'justify'  }}>
                José Machado is Associate Professor with Habilitation at University of Minho. His research interests span the topics of Data Science, Artificial Intelligence, Knowledge Engineering and Medical Informatics.
                He is director of the ALGORITMI R&D Center and the Director of the Ph.D program in Biomedical Engineering. He is a former member of the board of the Portuguese Association for AI and the chair of the IEEE Computational Intelligence Society, PT Chapter.
</GridItem>
            </GridContainer>
            <GridContainer justify="center" style={{ marginLeft: "1px", marginRight: "1px", marginBottom: '60px' }} >
              <GridItem xs={12} sm={12} md={4}>
                <CustomTabs
                  tabs={[
                    {
                      tabContent: (
                        <div>
                          <center><h3><b>Regina Sousa</b></h3></center>
                          <img style={{ width: '270px' }} src={rsousa}></img>
                          <GridContainer spacing={2} style={{ marginLeft: '2vw' }}>
                            <GridItem xs={12} sm={12} md={2}><Button onClick={() => window.open("", "_blank")}><WebIcon /></Button></GridItem>
                            <GridItem xs={12} sm={12} md={2}><Button onClick={() => window.open("https://www.researchgate.net/profile/Regina_Sousa6", "_blank")}><RGIcon /></Button></GridItem>
                            <GridItem xs={12} sm={12} md={2}><Button onClick={() => window.open("https://www.linkedin.com/in/reginasousa/", "_blank")}><LinkedInIcon /></Button></GridItem>
                            <GridItem xs={12} sm={12} md={2}><Button onClick={() => window.open("mailto:regina.sousa@algoritmi.uminho.pt", "_blank")}><MailIcon /></Button></GridItem>
                          </GridContainer>
                        </div>
                      )
                    },
                  ]}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={8} style={{ marginTop: '7vh', textAlign:'justify' }}>
               Regina Sousa is a Ph.D. student in Biomedical Engineering with technical skills in Artificial Intelligence, Business Intelligence, Big Data, Healthcare Information Systems, among others.
She collaborated in a professional project with TecMinho in the years 2017 and 2018 called DermoID -Núcleo de Investigação e Desenvolvimento de Dermocosméticos. In 2018 she was a trainer of children's computer programming in the company HappyCode Braga.
In 2019 she joined geoatributo as a software analyst where she prevailed until the end of the year. She took leave from the profession to start her Ph.D. in Biomedical Engineering in the Medical Informatics Branch.
In Jan 2020 he won a research fellowship in the Factory of the Future: Smart Facturing project where he still collaborates.
In Nov 2020 experienced the first activity as a guest lecturer in the course of Asian Institute of Technology (AIT) - Data Modeling and Management in the School of Engineering and Technology (SET) Asian Institute of Technology (AIT), Thailand.
In Jan 2021 she was a lecturer at Instituto Piaget Vila Nova de Gaia in the Information Systems course.
So far he has published 21 papers and 6 more are approved awaiting publication.
</GridItem>
            </GridContainer>
            <br /><br />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
