/*eslint-disable*/
import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "components/CustomButtons/Button.js";
import ScheduleIcon from '@material-ui/icons/Schedule';
import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import StarIcon from '@material-ui/icons/Star';
import HomeIcon from '@material-ui/icons/Home';
const useStyles = makeStyles(styles);


export default function HeaderLinks(props) {
  const classes = useStyles();
  const [state, setState] = useState({
    freguesias:[],
  });
  
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
      <Link style={{color: "inherit"}} to="/" >
        <Button
          color="transparent"
          target="_blank"
          className={classes.navLink}
        >
          <HomeIcon className={classes.icons} />Home
        </Button>
        </Link>
      <Link style={{color: "inherit"}} to="/schedule" >
        <Button
          color="transparent"
          target="_blank"
          className={classes.navLink}
        >
          <ScheduleIcon className={classes.icons} /> Schedule
        </Button>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
      <Link style={{color: "inherit"}} to="/speakers" >
        <Button
          color="transparent"
          target="_blank"
          className={classes.navLink}
        >
          <PeopleAltIcon  className={classes.icons} /> Invited Speakers
        </Button>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
      <Link style={{color: "inherit"}} to="/committee" >
        <Button
          color="transparent"
          target="_blank"
          className={classes.navLink}
        >
          <StarIcon className={classes.icons} /> Commitee
        </Button>
        </Link>
      </ListItem>
      
     
      
      
    </List>
  );
}
