import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function First() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>About</h2>
          <h4 className={classes.description}>
        The IEEE Computational Intelligence Society - Portuguese Chapter is the Portuguese branch of the Computational Intelligence Society and is integrated in IEEE Portugal Section.
        The IEEE Portugal Section is part of the Region 8 (Europe, Africa, Middle East and Russia) of the IEEE, has more than 1,500 members, who are part of a universe of professionals from different areas of technology, as well as a growing community of students from different Portuguese Higher Education Institutions.
          </h4>
        </GridItem>
      </GridContainer>
    </div>
  );
}
