import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import joseluis from '../../assets/img/joseluis.jpg'
import luiscorreia from '../../assets/img/lcorreia.jpg'
import daliladuraes from '../../assets/img/daliladuraes.jpg'
import vilaca from '../../assets/img/vilaca.png'
import pmelo from '../../assets/img/pmelo.jpg'
import amadureira from '../../assets/img/amadureira.jpg'
import luisossa from '../../assets/img/luisossa.jpg'
import abelha from '../../assets/img/abelha.png'
import hector from '../../assets/img/hector.png'
import esteban from '../../assets/img/esteban.png'
import flavio from '../../assets/img/flavio.jpg'
import joaof from '../../assets/img/joaof.jpg'
import tania from '../../assets/img/tania.png'
import javier from '../../assets/img/javier.jpeg'
import jaime from '../../assets/img/jaime.jpeg'
import helen from '../../assets/img/helen.jpeg'
import goreti from '../../assets/img/goreti.jpeg'
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import CustomTabs from "components/CustomTabs/CustomTabsSpeakers.js";
import { Divider } from "@material-ui/core";
const useStyles = makeStyles(styles);
const dashboardRoutes = [];

export default function SpeakersPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
 
  return (
    <div>
        <Link style={{color:'white'}} to="/" >
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand="IEEE CI Lectures"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      </Link>
      <Parallax small filter style={{background:" rgba(4, 35, 92, 0.8)"}}/>
      <div className={classNames(classes.main, classes.mainRaised)}>
      <div>
          <div >
            <GridContainer justify="center">
              <h1 className={classes.title}>Invited Speakers</h1>
            </GridContainer>
            <GridContainer justify="center">
              <h2 className={classes.title}>2023</h2>
            </GridContainer>
            <GridContainer justify="center" style={{ marginLeft: "1px", marginRight: "1px", marginBottom: '60px' }} >
            <GridItem xs={12} sm={12} md={0.5}></GridItem>
            <GridItem xs={12} sm={12} md={4} style={{marginTop:'300px'}}>
                <CustomTabs
                  tabs={[
                    {
                      tabContent: (
                        <img style={{width:'200px'}} src={goreti}></img>
                      )
                    },
                  ]}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <h2 className={classes.title}>Goreti Marreiros</h2>
                <h4 style={{ textAlign: "justify", maxWidth:'50vw' }}>
                Goreti Marreiros has Habilitation by Universidade do Minho in 2020, PhD in Informatics – Artificial Intelligence in 2008 by Universidade do Minho, Master in Information Managment in 2002 by Universidade do Porto Faculdade de Engenharia and Bachelor in computer Science in 1995 by Universidade do Porto Faculdade de Ciências. She is Coordinator Teacher with Habilitation in Instituto Superior de Engenharia do Porto (ISEP), Diretor and Senior researcher of GECAD - Grupo de investigação em Engenharia e Computação Inteligente para a Inovação e o Desenvolvimento, classified as Excellent by FCT, Sub Director of the Master in Engineering and Artificial Intelligence, Director of the post-graduation in BigData & Decision Making in ISEP and Member of ISEP Scientific Council. 
In the last years she has tough and been responsible for a number of courses on Artificial Intelligence at the graduate and postgraduate level. Participates and/or participated as Principal investigator in 18 projects (11 European projects (H2020;ITEA and ERASMUS+) and 7 national projects (FCT;P2020) and Researcher in 20 project(s)). 
Works in the area(s) of affective computing, ambient Intelligence, inteligente decision support systems. Her R&D work had application to different domains, namely: -Group Decision Support (in projects GIGADESSEA, EKRUCAmI, TheRoute, GROUPLANNER, SmarTravel) -Industry 4.0 (in projects MANTIS, InVALUE, INVALUE_PT, NIS, PIANISM, CYberfactory, SECOIIA, Ferrovia 4.0) -Health Care and wellbeing (in projects like  EKRUCAmI, PHE, AIRDOC, Inno4health, Secur-e-Health, FoodFriend) -Power and Energy Systems (in projects like DREAM-GO, FUSE-IT, M2MGrids, GREEDI, SIMOCE, CENERGETIC) -Security (in project SASSI, SAFECARE, SATIE) Published 50 articles in journals. Has more the 50 books chapters and 60 articles in internacional conferences. Organized 24 international conferences/workshops. Supervised 4 PhD thesis(es) e co-supervised 4. Supervised 20 MSc dissertation(s) e co-supervised 4. Along the years she reviewed papers for more than 30 different journals and was member of more than 100 internacional conferences scientific program committes. Has received several awards and/or honors. In their professional activities interacted with more than 100 collaborator(s) from 7 different countries (Spain, Canada, Czech republic, Denmark, Belgium, Philippines, Poland and South Korea).

                </h4>
                <h4>
                She has been requested to advise and consult for various NGOs, governments, companies, and universities for her expertise in the use of technology to enhance and extend learning. This includes work for the United Nations (UNESCO, ITU), Oxford University, Cambridge University, The World Bank, and USAID. Her scholarly efforts have gained awards, and recognition. For example, Crompton is listed in Stanford’s top 2% of scientists in the world and the United Nations formally recognized her as one of nine experts in the world for mobile learning.
                </h4>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center" style={{ marginLeft: "1px", marginRight: "1px", marginBottom: '60px' }} >
            <GridItem xs={12} sm={12} md={0.5}></GridItem>
            <GridItem xs={12} sm={12} md={4} style={{marginTop:'50px'}}>
                <CustomTabs
                  tabs={[
                    {
                      tabContent: (
                        <img style={{width:'200px'}} src={helen}></img>
                      )
                    },
                  ]}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <h2 className={classes.title}>Helen Crompton</h2>
                <h4 style={{ textAlign: "justify", maxWidth:'50vw' }}>
                Dr. Helen Crompton is an Associate Professor of Instructional Technology at Old Dominion University. With a Ph.D. in Mathematics Education and Technology in Education, she focuses her research on technology integration and learning with technologies, such as mobile devices, artificial intelligence, and virtual/mixed/augmented reality. She is the director of the Technology Enhanced Learning Lab (TELL), the Virtual Reality Lab and Dr. Crompton is the CEO of Crompton Consulting.
                </h4>
                <h4>
                She has been requested to advise and consult for various NGOs, governments, companies, and universities for her expertise in the use of technology to enhance and extend learning. This includes work for the United Nations (UNESCO, ITU), Oxford University, Cambridge University, The World Bank, and USAID. Her scholarly efforts have gained awards, and recognition. For example, Crompton is listed in Stanford’s top 2% of scientists in the world and the United Nations formally recognized her as one of nine experts in the world for mobile learning.
                </h4>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center" style={{ marginLeft: "1px", marginRight: "1px", marginBottom: '60px' }} >
            <GridItem xs={12} sm={12} md={0.5}></GridItem>
            <GridItem xs={12} sm={12} md={4} style={{marginTop:'50px'}}>
                <CustomTabs
                  tabs={[
                    {
                      tabContent: (
                        <img style={{width:'200px'}} src={jaime}></img>
                      )
                    },
                  ]}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <h2 className={classes.title}>Jaime Andres Rincon Arango</h2>
                <h4 style={{ textAlign: "justify", maxWidth:'50vw' }}>
                Jaime Andres Rincon Arango is a postdoctoral researcher at the Valencian Institute for Research in Artificial Intelligence (VRAIN) at  the Polytechnic University of Valencia. He holds a degree in Biomedical Engineering from the Manuela Beltran University (Colombia), a master's degree in Artificial Intelligence from the Polytechnic University of Valencia and a PhD in Computer Science from the  Polytechnic University of Valencia. Her main research activities focus  on IoT, IoMT, Cognitive Assistants, assistive robotics for elderly  people and Edge AI. He is author or co-author of more than 50 articles in specialized journals and national and international conferences.
                </h4>
              </GridItem>
            </GridContainer>
            
            <GridContainer justify="center">
              <h2 className={classes.title}>2022</h2>
            </GridContainer>
            
            <GridContainer justify="center" style={{ marginLeft: "1px", marginRight: "1px", marginBottom: '60px' }} >
            <GridItem xs={12} sm={12} md={0.5}></GridItem>
            <GridItem xs={12} sm={12} md={4} style={{marginTop:'140px'}}>
                <CustomTabs
                  tabs={[
                    {
                      tabContent: (
                        <img style={{width:'200px'}} src={javier}></img>
                      )
                    },
                  ]}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <h2 className={classes.title}>Javier Bajo Perez</h2>
                <h4 style={{ textAlign: "justify", maxWidth:'50vw' }}>
                Dr. Javier Bajo, full professor at the Department of Artificial Intelligence, Computer Science School at Universidad Politécnica de Madrid (UPM). He helds (since 17/12/2020) the position of Director of the UPM Research Center in AI. Before (03/05/2019-17/12/2020) he was Director of the UPM-Accenture AI.nnovation Space, Innovation Center in Artificial Intelligence. Before this, he was Director of the Department of Artificial Intelligence, Secretary of the PhD program in Artificial Intelligence and Coordinator of the Research Master in Artificial Intelligence at UPM. He also hold the position of Director of the Data Center at the Pontifical University of Salamanca (13-10/2010 - 08-11-2012).
                Javier has been visiting professor at the Dartmouth College (USA, 2016, Salvador de Madariaga Grant), Osaka Institute of Technology (Japan, 2011, JSPS Invitation Fellowship Program for Research in Japan) and IRIT-University of Toulouse (France, 2013, 2014, 2015).
                He is member of the Ontology Engineering Group at UPM. His main lines of research are Social Intelligence and Artificial an Hybrid Societies, Intelligent Agents and Multiagent Systems, Machine Learning. He has supervised 14 Ph.D thesis, parti cipated in more than 50 research projects (in most of them as principal investigator) and published more than 300 articles in recognized journals (81 JCR papers) and conferences. His h-index is 39. He is founder of the PAAMS series of conferences, and is an IEEE, ACM and ISIF member.
                </h4>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center" style={{ marginLeft: "1px", marginRight: "1px", marginBottom: '60px' }} >
            <GridItem xs={12} sm={12} md={0.5}></GridItem>
            <GridItem xs={12} sm={12} md={4} style={{marginTop:'80px'}}>
                <CustomTabs
                  tabs={[
                    {
                      tabContent: (
                        <img style={{width:'200px'}} src={tania}></img>
                      )
                    },
                  ]}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <h2 className={classes.title}>Tânia Di Mascio</h2>
                <h4 style={{ textAlign: "justify", maxWidth:'50vw' }}>
                  Tania Di Mascio is an associate professor in Information and Elaboration Systems at the Department of Information Engineering, Computer Science, and Mathematics (DISIM) of the University of L'Aquila.
                  She is an Innovation Manager, she is co-founder and CXO of the Project Innovation start-up. She is also Data Protection Manager and User-Centered Design team coordinator within several EU projects.
                  She obtained a Ph.D. degree working in Human-Computer Interaction (HCI) research field and she was awarded a master’s degree in Electronic Engineering. Her primary research activities are in HCI, user interface usability and accessibility, Assistive Technology as well as in Technology Enhanced Learning.
                  She is the author or co-author of more than 100 papers in peer-reviewed journals and international and national conferences. She is a Steering Committees member of the International Conference in Methodologies and Intelligent Systems for Technology Enhanced Learning, Information Visualization of Learning Analytics.
                  She is the UnivAQ responsible for the CINI AT node. She is an active member of SIGCHI, the center of excellence of DEWS and HiPEAC. She coordinates the ATES@UnivAQ group
                </h4>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center" style={{ marginLeft: "1px", marginRight: "1px", marginBottom: '60px' }} >
            <GridItem xs={12} sm={12} md={0.5}></GridItem>
            <GridItem xs={12} sm={12} md={4} style={{marginTop:'80px'}}>
                <CustomTabs
                  tabs={[
                    {
                      tabContent: (
                        <img style={{width:'200px'}} src={joaof}></img>
                      )
                    },
                  ]}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <h2 className={classes.title}>João C. Ferreira </h2>
                <h4 style={{ textAlign: "justify", maxWidth:'50vw' }}>
                João C. Ferreira is Professor at ISCTE-IUL.  
                His research interests are in: Data Science, Text Mining, IoT, Artificial Intelligence (AI), Blockchain and AI applications in health, energy and transportation. 
                He has published more than 250 papers in computer science. He has executed more than 30 projects (6 as PI). 
                Coordinator of the Master of Decision Support Systems and Professional Master for the Digitization of Business.
                </h4>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center" style={{ marginLeft: "1px", marginRight: "1px", marginBottom: '60px' }} >
            <GridItem xs={12} sm={12} md={0.5}></GridItem>
            <GridItem xs={12} sm={12} md={4} style={{marginTop:'80px'}}>
                <CustomTabs
                  tabs={[
                    {
                      tabContent: (
                        <img style={{width:'200px'}} src={flavio}></img>
                      )
                    },
                  ]}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <h2 className={classes.title}>Flávio Santos</h2>
                <h4 style={{ textAlign: "justify", maxWidth:'50vw' }}>
                Flávio Santos is a PhD Candidate at the Centro de Informática (CIn) in Universidade Federal de Pernambuco. 
                Besides, he is a researcher at the ALGORITMI Center at the School of Engineering of the University of Minho, Braga, Portugal. 
                He has developed scientific research in Deep Learning, with applications in Natural Language Processing and Computer Vision. 
                His primary research interests are deep learning interpretability, adversarial robustness, and right for right reasons models. 
                He has more than 29 publications on conferences and journals.
                </h4>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center" style={{ marginLeft: "1px", marginRight: "1px", marginBottom: '60px' }} >
            <GridItem xs={12} sm={12} md={0.5}></GridItem>
            <GridItem xs={12} sm={12} md={4} style={{marginTop:'80px'}}>
                <CustomTabs
                  tabs={[
                    {
                      tabContent: (
                        <img style={{width:'200px'}} src={esteban}></img>
                      )
                    },
                  ]}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <h2 className={classes.title}>Esteban Jove</h2>
                <h4 style={{ textAlign: "justify", maxWidth:'50vw' }}>
                Esteban Jove is currently Assistant Professor at the University of A Coruña (UDC) in the Department of Industrial Engineering.
                His research career began in 2015, in Technological Automotive Center of Galicia, focusing on the application of artificial intelligence and electric vehicles. 
                Then, in 2016, his research career continues in the UDC, where he belongs the Cybernetic Technical Science Group and CITIC centre, and also in the University of La Laguna, where he obtained the PhD in Industrial, Computer Science and Environmental Engineering.
                His main research lines are focused on hybrid intelligent systems to model non-linear systems using artificial intelligent techniques combined with clustering methods. 
                This proposal is successfully applied to predict a wide range of industrial and biological systems, among others. He also work in new research lines dealing with anomaly detection using one-class techniques and projectionist methods in industrial processes and cybersecurity systems. 
                </h4>
              </GridItem>
            </GridContainer>
            
            <Divider/>
            <GridContainer justify="center">
              <h2 className={classes.title}>2021</h2>
            </GridContainer>
            <GridContainer justify="center" style={{ marginLeft: "1px", marginRight: "1px", marginBottom: '60px' }} >
            <GridItem xs={12} sm={12} md={0.5}></GridItem>
            <GridItem xs={12} sm={12} md={4} style={{marginTop:'50px'}} >
                <CustomTabs
                  tabs={[
                    {
                      tabContent: (
                        <img style={{width:'230px'}} src={hector}></img>
                      )
                    },
                  ]}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <h2 className={classes.title}>Héctor Alaiz Moretón</h2>
                <h4 style={{ textAlign: "justify", maxWidth:'50vw' }}>H. Alaiz-Moreton received his degree in Computer Science, performing the final project at Dublin
Institute of Technology, in 2003. He received his PhD in Information Technologies in 2008
(University of Leon). He has worked as a lecturer since 2005 at the School of Engineering at the
University of Leon.
His research interests include knowledge engineering, machine and deep learning, networks
communication, and security. He has several works published in international conferences, as well
as books, more than 80 scientific publications between JCR papers, Lecture Notes and Scientific
Workshops. He has been a member of scientific committees in conferences.
He has headed several PhD Thesis and research competitive projects.
Actually, he is the vice main of RIASC (INSTITUTE OF APPLIED SCIENCES TO
CYBERSECURITY).</h4>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center" style={{ marginLeft: "1px", marginRight: "1px", marginBottom: '60px' }} >
            <GridItem xs={12} sm={12} md={0.5}></GridItem>
            <GridItem xs={12} sm={12} md={4} style={{marginTop:'50px'}} >
                <CustomTabs
                  tabs={[
                    {
                      tabContent: (
                        <img style={{width:'230px'}} src={abelha}></img>
                      )
                    },
                  ]}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <h2 className={classes.title}>António Abelha</h2>
                <h4 style={{ textAlign: "justify", maxWidth:'50vw' }}>António Abelha is Assistant Professor in the Informatics Department and an integrated member of the
ALGORITMI Center, both from the School of Engineering at the University of Minho. He is also director of the
Integrated Master in Biomedical Engineering course at the University of Minho. His areas of scientific interest are
Data Sciences, Artificial Intelligence and Biomedical Informatics, where he has been carrying out scientific research
for over 20 years. He is the author of more than 200 publications in international magazines, books and conference
proceedings.</h4>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center" style={{ marginLeft: "1px", marginRight: "1px", marginBottom: '60px' }} >
            <GridItem xs={12} sm={12} md={0.5}></GridItem>
            <GridItem xs={12} sm={12} md={4} style={{marginTop:'100px'}} >
                <CustomTabs
                  tabs={[
                    {
                      tabContent: (
                        <img style={{width:'230px'}} src={luisossa}></img>
                      )
                    },
                  ]}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <h2 className={classes.title}>Luis Fernando Castillo</h2>
                <h4 style={{ textAlign: "justify", maxWidth:'50vw' }}>Systems Engineer from the Universidad Autónoma de Manizales-Colombia, PhD in
Computer Science and Automation from the Universidad de Salamanca-Spain.
FullProfessor of the Department of Systems and Computer Science of the University of
Caldas, Senior Researcher of MinCiencias, Leader of the Research Group GITIR since 2013
- 2018, peer of MinCiencias, international evaluator of the Iberus program in Spain, member
of the AIR Institute (International Research Institute for Artificial).
Visiting Professor at the Universities of Salamanca, Zaragoza (Spain), Instituto Tecnológico
Ciudad Guzmán (Mexico), U. de Minho (Portugal), Universidad Tecnológica de Panamá.
Member of the Colombian Society of Computing, chapter Artificial Intelligence.
Coordinator of the Master in Management and Development of Software Projects of the
Universidad Autónoma de Manizales 2005-2011, coordinator of the Master in Bioinformatics
and Computational Biology of the Universidad de Caldas 2016 - 2021, Dean of the Faculty of
Engineering of the U. de Caldas 2014-2018.
Member of CODECTI (Departmental Council for Science, Technology and Innovation of the
Department of CALDAS) 2014-2016.
Professor of the PhD Engineering of the University of Caldas, Cognitive Sciences
(Autonomous University of Manizales) and Industrial Engineering and Organizations of the
UNAL Manizales
Coordinator of Node 3 Colombia towards a new Productive, Competitive and Sustainable
Model of the Mission of Wise Men for Caldas 2020-2021.</h4>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center" style={{ marginLeft: "1px", marginRight: "1px", marginBottom: '60px' }} >
            <GridItem xs={12} sm={12} md={0.5}></GridItem>
            <GridItem xs={12} sm={12} md={4} style={{marginTop:'100px'}} >
                <CustomTabs
                  tabs={[
                    {
                      tabContent: (
                        <img style={{width:'230px'}} src={amadureira}></img>
                      )
                    },
                  ]}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <h2 className={classes.title}>Ana Madureira</h2>
                <h4 style={{ textAlign: "justify", maxWidth:'50vw' }}>Ana Madureira was born in Moçambique, in 1969. She got his BSc degree in Computer Engineering in 1993
from ISEP, master’s degree in electrical and Computers Engineering–Industrial Informatics, in 1996, from
FEUP, and the PhD degree in Production and Systems, in 2003, from University of Minho, Portugal. She
became IEEE Senior Member in 2010. She had been Chair of IEEE Portugal Section (2015-2017), Vice-chair
of IEEE Portugal Section (2011-2014) and Chair/Vice-Chair of IEEE-CIS Portuguese chapter. She was Chair
of University Department of IEEE R8 Educational Activities Sub-Committee (2017-2018). She was IEEE R8
Secretary (2019-2020). She is External Member Evaluation Committee of the Agency for Assessment and
Accreditation of Higher Education - A3ES (Agência de Avaliação e Acreditação do Ensino Superior) for the
scientific area of Informatics of Polytechnic Higher Education (2012- ). Currently she is Coordinator Professor
at the Institute of Engineering–Polytechnic of Porto (ISEP/IPP) and Director of the Interdisciplinary Studies
Research Center (ISRC). In the last few years, she was author of more than 100 scientific papers in scientific
conference proceedings, journals and books. Her main areas of research are: artificial intelligence;
metaheuristics; user modeling; dynamic scheduling; data science</h4>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center" style={{ marginLeft: "1px", marginRight: "1px", marginBottom: '60px' }} >
            <GridItem xs={12} sm={12} md={0.5}></GridItem>
            <GridItem xs={12} sm={12} md={4} style={{marginTop:'100px'}} >
                <CustomTabs
                  tabs={[
                    {
                      tabContent: (
                        <img style={{width:'270px'}} src={vilaca}></img>
                      )
                    },
                  ]}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <h2 className={classes.title}>João Vilaça</h2>
                <h4 style={{ textAlign: "justify", maxWidth:'50vw' }}>João L. Vilaça graduated in Industrial Electronics and Computers at University of Minho, Portugal in 2004.In
2008, he obtained the PhD degree in Industrial Electronics from the University of Minho, Guimarães, Portugal.
From 2009, he has been at the Technology Department of the School of Technology, Polytechnic Institute of
Cávado and Ave, Portugal, where he is Associate Professor (since 2017) and the head of the Technology
Department (since 2015). From 2009 to 2018, he was also researcher at the ICVS/3B’s Laboratory, university
of Minho, Portugal. In February 2018, he joined the 2Ai as Associate Research, where he is currently its
Director. João L. Vilaça direct his daily efforts towards challenge driven research, aiming to solve practical
problems faced by clinicians and surgeons in their daily practice. His research work focuses on augmenting
the information at the disposal of the physician and potentiating its precision. To this end, he aims to integrate
multi-modality image sources combined with machine learning methodologies and personalized models to
improve clinicians’ scope of the patient’s anatomy, as well as providing accurate diagnosis and navigation
tools. His work is focused in the areas of medical imaging processing, image tracking, deep learning, artificial
intelligence and collaborative robots, with over 100 indexed papers. He found and co-chair the IEEE
conference on Series Games and Applications for Heath. More than 80 students have obtained the degree
under his supervision, including PhD, master and undergraduate degrees. In projects that he was or is
involved has always favoured the development of prototypes that, in addition to a research component, have
an industrial application. He has also privileged in his research field, the closer contact with companies and
other institutions of research and development. Together with the scientific achievements, the technologies
developed in his group give rise to 7 patents, the formation of one spin-off company, iSurgical3D, in the area
of personalized medicine - design of patient-specific thoracic implants based in medical image processing and
computer-aided technologies and knowledge transfer to international medical companies, namely a navigation
system for urologic minimal invasive percutanueos interventions and Medical Image Tracking methodologies
for customization of image tracking solutions for cardiac dense motion field estimation.</h4>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center" style={{ marginLeft: "1px", marginRight: "1px", marginBottom: '60px' }} >
            <GridItem xs={12} sm={12} md={0.5}></GridItem>
            <GridItem xs={12} sm={12} md={4} style={{marginTop:'100px'}} >
                <CustomTabs
                  tabs={[
                    {
                      tabContent: (
                        <img style={{width:'270px'}} src={daliladuraes}></img>
                      )
                    },
                  ]}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <h2 className={classes.title}>Dalila Durães</h2>
                <h4 style={{ textAlign: "justify", maxWidth:'50vw' }}>Dalila Durães is a pos-doctoral researcher at the ALGORITMI Center at the School of
Engineering of the University of Minho, Braga, Portugal, where she collaborates with the
group ISLab - Synthetic Intelligence lab.
She has two International PhD: one in Education Science in Professor, Curricula and
Educations Institutions at University of Granada, Spain; and other in Artificial Intelligence
at Technical University of Madrid, Spain.
She develops scientific research in the field of Artificial Intelligence, with applications in
Intelligent Tutoring Systems, Human-Computer Interaction, Behavioral Analysis, Natural
Language Processing, Sentiment Analysis, Human Action Recognition, and the
incorporation of AI methods and techniques in these fields.
Dalila has authored of over 31 publications in peer-reviewed international journals, book
chapters and conference proceedings. She is also member of the editorial board of several
international journals. During the last years she has served as an expert/reviewer of
several institutions such as Conference Papers, journal. She was also co-oriented of MSc
and PhD students, and she was member of jury of doctoral thesis and MSc thesis.
She is a member of the Portuguese Association for Artificial Intelligence (APPIA) and,
since January 2020, a member of the association Direction Board.</h4>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center" style={{ marginLeft: "1px", marginRight: "1px", marginBottom: '60px' }} >
            <GridItem xs={12} sm={12} md={0.5}></GridItem>
            <GridItem xs={12} sm={12} md={4} >
                <CustomTabs
                  tabs={[
                    {
                      tabContent: (
                        <img style={{width:'270px'}} src={joseluis}></img>
                      )
                    },
                  ]}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <h2 className={classes.title}>José Luis Calvo Rolle</h2>
                <h4 style={{ textAlign: "justify", maxWidth:'50vw' }}>José-Luis Calvo Rolle received MS and PhD degrees in industrial engineering
                from the University of Leon in 2004 and 2007, respectively. He is an associate
                professor of automatic control and the head of the Industrial Engineering
                Department, Faculty of Engineering, University of A Coruna, Spain. His main
                research areas are associated with the application of intelligent systems for
                optimization, modeling, fault detection, diagnosis, and control. </h4>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center" style={{ marginLeft: "1px", marginRight: "1px", marginBottom: '60px' }} >
            <GridItem xs={12} sm={12} md={0.5}></GridItem>
            <GridItem xs={12} sm={12} md={4} style={{marginTop:'40px'}}>
                <CustomTabs
                  tabs={[
                    {
                      tabContent: (
                        <img style={{width:'300px'}} src={luiscorreia}></img>
                      )
                    },
                  ]}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <h2 className={classes.title}>Luis Correia</h2>
                <h4 style={{ textAlign: "justify", maxWidth:'50vw' }}>
Luís M.P. Correia is professor at the Department of Informatics of Faculdade de Ciências of Universidade de Lisboa, Portugal. He obtained an Electrical Engineering degree from Instituto Superior Técnico of Universidade Técnica de Lisboa (1982), a PhD in Informatics from Universidade Nova de Lisboa (1995), and habilitation from Universidade de Lisboa (2009).
He lead the Laboratory of Agent Modelling, LabMAg (2004-2014), the Informatics Department (2012-2015), and the MAS group of BioISI (2015-2020). Currently he is a researcher at LASIGE, ULisboa.
His research interests are artificial life, self-organisation, multi-agent systems, autonomous robots and data mining. He has participated in several international projects and serves as evaluator of EU, ANR (France) and other international projects, as well as in several journals and international conferences.
Besides lecturing in the three cycles of informatics he has also appointments in the cognitive science and in the complexity sciences post-graduations of ULisboa.
</h4>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center" style={{ marginLeft: "1px", marginRight: "1px", marginBottom: '60px' }} >
            <GridItem xs={12} sm={12} md={0.5}></GridItem>
            <GridItem xs={12} sm={12} md={4} style={{marginTop:'120px'}}>
                <CustomTabs
                  tabs={[
                    {
                      tabContent: (
                        <img style={{width:'200px'}} src={pmelo}></img>
                      )
                    },
                  ]}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <h2 className={classes.title}>Pedro Melo Pinto</h2>
                <h4 style={{ textAlign: "justify", maxWidth:'50vw' }}>
Pedro Melo Pinto graduated from University of Porto in 1984 and received a Ph.D. from the Universidade de Trás-os-Montes e Alto Douro in 1998, where he served as Pro-rector and is Full Professor.
He is a member of the CITAB-UTAD (Centre for the Research and Technology of Agro-Environmental and Biological Sciences) and of Centro ALGORITMI (Universidade do Minho). He has published over 100 papers in international journals, book chapters and conferences. He has been involved in more than 20 national and international research projects, he currently serves as member of Comissão de Acompanhamento das Infraestruturas Estratégicas Nacionais - Área Temáticas Digitais and of the editorial board of Remote Sensing and Journal of Advanced Computational Intelligence and Intelligent Informatics and he has co- edited the Fuzzy Sets & Systems special issue on Fuzzy Methods for Knowledge- Based Systems.
He is also a member of the reviewer board for many journals including Information Sciences, Expert Systems with Applications, Fuzzy Sets and Systems, Sensors and Food Chemistry. He was Chair of EuroFuse 2011 and NATO 2001 Advanced Research Workshop. He is member of the International Society of Applied Intelligence and of APPIA (Associação Portuguesa Para a Inteligência Artificial).
His current scientific interests include Computer Vision, Digital Image Processing and Machine Learning, in particular its applications to the Agro-forestry area.
</h4>
              </GridItem>
            </GridContainer>
           
            <br /><br />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
