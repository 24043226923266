import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.9.0";

import SchedulePage from "views/SchedulePage/SchedulePage.js";
import HomePage from "views/HomePage/HomePage.js";
import SpeakersPage from "views/SpeakersPage/SpeakersPage.js";
import CommitteePage from "views/CommitteePage/CommitteePage.js";

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/schedule" component={SchedulePage} />
      <Route path="/speakers" component={SpeakersPage} />
      <Route path="/committee/" component={CommitteePage} />
      <Route path="/" component={HomePage} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
